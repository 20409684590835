import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import StudentProfile from "../components/StudentProfile"
import WorkEnvironment from "../components/WorkEnvironment"
import TitleMastery from "../components/TitleMastery"

export const pageQuery = graphql`
  query PageTemplateMasteryQuery($id: String!) {
    graphCmsB5PaginasdeMaestria(id: { eq: $id }) {
      tituloDeLaPagina
      colorDeLaPagina
      componentenivel1delaMaestria {
        ... on GraphCMS_C1TituloEImagenEspecialidad {
          id
          imagen {
            gatsbyImageData
          }
          titulo
          posicionDelCuadroDeTexto
        }
      }
      componentenivel2delaMaestria {
        ... on GraphCMS_C2PerfilDeIngresoYEgreso {
          id
          iconoDelPerfilDeIngreso {
            gatsbyImageData
          }
          descripcionDelPerfilDeIngreso {
            raw
          }
          iconoDelPerfilDeEgreso {
            gatsbyImageData
          }
          descripcionDelPerfilDeEgreso {
            raw
          }
        }
      }
      componentenivel3delaMaestria {
        ... on GraphCMS_C2CampoLaboral {
          id
          imagenDeFondo {
            gatsbyImageData
          }
          descripcionDelCampoLaboral {
            raw
          }
          posicionDelCampoDeTexto
        }
      }
    }
  }
`

const PageMastery = ({ data: { graphCmsB5PaginasdeMaestria } }) => {
  let theme =
    graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Rojo"
      ? "#ff0000"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Azul"
      ? "#155d7c"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Cian"
      ? "#70c4a4"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Verde_Lima"
      ? "#4b7c1c"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Verde_Obscuro"
      ? "#0a6326"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Rosa"
      ? "#b30667"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Morado"
      ? "#4a017d"
      : "#ff0000"

  let colorIcon =
    graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Rojo"
      ? "red"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Azul"
      ? "blue"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Cian"
      ? "cian"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Verde_Lima"
      ? "lightgreen"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Verde_Obscuro"
      ? "darkgreen"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Rosa"
      ? "pink"
      : graphCmsB5PaginasdeMaestria.colorDeLaPagina === "Morado"
      ? "purple"
      : "red"

  return (
    <>
      <Seo title={graphCmsB5PaginasdeMaestria.tituloDeLaPagina} />
      <section className="coursepage-section">
        {graphCmsB5PaginasdeMaestria.componentenivel1delaMaestria ? (
          <TitleMastery
            component={graphCmsB5PaginasdeMaestria.componentenivel1delaMaestria}
            theme={theme}
            colorIcon={colorIcon}
          />
        ) : (
          ""
        )}
        {graphCmsB5PaginasdeMaestria.componentenivel2delaMaestria ? (
          <StudentProfile
            component={graphCmsB5PaginasdeMaestria.componentenivel2delaMaestria}
            theme={theme}
            colorIcon={colorIcon}
          />
        ) : (
          ""
        )}
        {graphCmsB5PaginasdeMaestria.componentenivel3delaMaestria ? (
          <WorkEnvironment
            component={graphCmsB5PaginasdeMaestria.componentenivel3delaMaestria}
            theme={theme}
            colorIcon={colorIcon}
          />
        ) : (
          ""
        )}
      </section>
    </>
  )
}

export default PageMastery
