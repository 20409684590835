import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from "@graphcms/rich-text-react-renderer"

const StudentProfile = ({ component, colorIcon }) => (
  <section className="studentprofile-section">
    <div className="studentprofile-content">
      <div className="studentprofile-grid">
        <div>
          <div className="studentprofile-box">
            <div className="studentprofile-icon-box">
              <div className="studentprofile-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoDelPerfilDeIngreso)}
                alt=""
                className={`studentprofile-icon icon-${colorIcon}`}
              />
            </div>
            <p className="studentprofile-title">Perfil de Ingreso</p>
            <p className="studentprofile-text">
              <RichText content={component.descripcionDelPerfilDeIngreso.raw} />
            </p>
          </div>
        </div>
        <div>
          <div className="studentprofile-box">
            <div className="studentprofile-icon-box">
              <div className="studentprofile-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoDelPerfilDeEgreso)}
                alt=""
                className={`studentprofile-icon icon-${colorIcon}`}
              />
            </div>
            <p className="studentprofile-title">Perfil de Egreso</p>
            <p className="studentprofile-text">
              <RichText content={component.descripcionDelPerfilDeEgreso.raw} />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default StudentProfile
