import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TitleMastery = ({ theme, component }) => (
  <section className="titlemastery-section">
    {component.posicionDelCuadroDeTexto === "Izquierda" ? (
      <>
        <div className="titlemastery-title-div titlemastery-animation1l">
          <div
            style={{ backgroundColor: `${theme}` }}
            className="titlemastery-title-shape"
          />
          <div className="titlemastery-title-box">
            <p
              style={{ borderColor: `${theme}` }}
              className="titlemastery-title"
            >
              <em>
                Maestría en <br />
              </em>
              {component.titulo}
            </p>
          </div>
        </div>
        <GatsbyImage
          className="titlemastery-image"
          image={getImage(component.imagen)}
          alt=""
        />
      </>
    ) : (
      <>
        <GatsbyImage
          className="titlemastery-image-left"
          image={getImage(component.imagen)}
          alt=""
        />
        <div className="titlemastery-title-div-left">
          <div
            style={{ backgroundColor: `${theme}` }}
            className="titlemastery-title-shape-left"
          />
          <div className="titlemastery-title-box-left">
            <p
              style={{ borderColor: `${theme}` }}
              className="titlemastery-title-left"
            >
              <em>
                Maestría en <br />
              </em>
              {component.titulo}
            </p>
          </div>
        </div>
      </>
    )}
  </section>
)

export default TitleMastery
